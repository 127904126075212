import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Button from "@amzn/meridian/button"
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from "@amzn/meridian/table"
import Textarea from "@amzn/meridian/textarea"
import deleteTokens from "@amzn/meridian-tokens/base/icon/delete"
import editTokens from "@amzn/meridian-tokens/base/icon/edit"
import SearchField from "@amzn/meridian/search-field"
import Icon from "@amzn/meridian/icon"
import Tooltip from "@amzn/meridian/tooltip"
import plusCircleTokens from "@amzn/meridian-tokens/base/icon/plus-circle"
import saveTokens from "@amzn/meridian-tokens/base/icon/save"
import closeLargeTokens from "@amzn/meridian-tokens/base/icon/close-large"
import Link from "@amzn/meridian/link"
import DatePicker from "@amzn/meridian/date-picker"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"

import Text from "@amzn/meridian/text"
import {
  addAuditIssues,
  deleteAuditIssues,
  editAuditIssues,
  retrieveAuditIssues,
} from "../../redux/audit/action"
import { useFilters, useSort } from "../../hooks"
import { Select, SelectOption } from "../../components/app/form-fields"
import { AddAuditIssuesModal } from "./modals"
import AddAuditFileModal from "./modals/add-audit-file-modal"
import {
  auditIssueStatusOptions,
  auditIssueTypeOptions,
  auditPriorityOptions,
  auditStatusOptions,
} from "../../data/options"
import AuditSelect from "../../components/audit/audit-select"
import StatusTag from "../../components/app/status-tag"

const AuditReportIssueList = props => {
  const { auditReport, testLocation, cm } = props
  const [addAuditModalOpen, setAddAuditModalOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [auditIssueList, setAuditIssueList] = useState(null)
  const [oldAuditIssueList, setOldAuditIssueList] = useState(null)
  const [addAuditFileModalOpen, setAddAuditFileModalOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState()

  const reduxDispatch = useDispatch()

  const { isCMUser, isAdmin, username } = useSelector(
    state => state.userReducer
  )
  const { auditIssues } = useSelector(state => state.auditReducer)
  const { equipment } = useSelector(state => state.equipmentReducer)

  useEffect(() => {
    const auditIssuesCopy = JSON.parse(JSON.stringify(auditIssues))

    setAuditIssueList(auditIssues)
    setOldAuditIssueList(auditIssuesCopy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditIssues])

  const onCloseButtonClick = () => {
    reduxDispatch(retrieveAuditIssues(auditReport.auditReportId))
  }

  const onClickSaveButton = () => {
    setEditMode(false)
    let editAuditIssueList = auditIssueList.map((auditIssue, index) => {
      if (oldAuditIssueList[index].isClosed) {
        // reset the resolved date if reopen the issue
        if (!auditIssue.isClosed) {
          auditIssueList[index].resolvedDate = null
          auditIssueList[index].resolvedBy = null
        }
      } else {
        // set resolve date to today when close the issue
        if (auditIssue.isClosed) {
          auditIssueList[index].resolvedDate = new Date()
            .toISOString()
            .slice(0, 10)
          auditIssueList[index].resolvedBy = username
        }
      }
      return auditIssue
    })
    reduxDispatch(
      editAuditIssues(editAuditIssueList, auditReport.auditReportId)
    )
    // onCloseButtonClick()
  }

  const addAuditIssueFileList = fileList => {
    let newAuditIssueList = auditIssueList
    let previousFileList = newAuditIssueList[currentIndex].retrieveFileListByEquipment
    let currentFileList = previousFileList.concat(fileList?.flat())
    
    newAuditIssueList[currentIndex].retrieveFileListByEquipment = currentFileList
    setAuditIssueList(newAuditIssueList)
  }

  const setChangeEquipments = (values, index) => {
    let selectedEquipments = []
    values.forEach(val => {
      selectedEquipments.push(equipment?.find(e => e.equipmentId === val))
    })
    auditIssueList[index].equipmentsWithIssue = selectedEquipments

    setAuditIssueList([...auditIssueList])
  }

  const [keyword, setKeyword] = useState("")

  const { sortedData, onSort, sortColumn, sortDirection } = useSort(
    auditIssueList || []
  )

  return (
    <Row>
      <Column width="100%">
        <Table
          headerRows={1}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={onSort}
          showDividers={true}
          stickyHeaderRow={true}
          // showStripes={true}
          fixHeaderRows={true}
        >
          <TableActionBar widths={[300]}>
            <SearchField
              value={keyword}
              onChange={setKeyword}
              placeholder="Search for..."
              type="text"
              onSubmit={() => {}}
            />
            <Tooltip position="top" title="Add new issue" id="add">
              <Button
                type="icon"
                // disabled={!isAdmin || editMode}
                onClick={() => setAddAuditModalOpen(true)}
              >
                <Icon tokens={plusCircleTokens}>Save</Icon>
              </Button>
            </Tooltip>
            {editMode ? (
              <Tooltip position="top" title="Save changes" id="save">
                <Button type="icon" onClick={onClickSaveButton}>
                  <Icon tokens={saveTokens}>Save</Icon>
                </Button>
              </Tooltip>
            ) : (
              <Tooltip position="top" title="Edit issue(s)" id="edit">
                <Button
                  disabled={!sortedData || sortedData.length < 1}
                  type="icon"
                  onClick={() => setEditMode(true)}
                >
                  <Icon tokens={editTokens}>Edit</Icon>
                </Button>
              </Tooltip>
            )}
            {editMode && (
              <Tooltip position="top" title="Cancel changes" id="cancel">
                <Button
                  type="icon"
                  onClick={() => {
                    setEditMode(false)
                    onCloseButtonClick()
                  }}
                >
                  <Icon tokens={closeLargeTokens}>Close</Icon>
                </Button>
              </Tooltip>
            )}
          </TableActionBar>
          <TableRow>
            {/*<TableCell></TableCell>*/}
            <TableCell sortColumn="equipementNames" width={150}>
              Related Equipment
            </TableCell>
            <TableCell sortColumn="description">Issue Description</TableCell>
            <TableCell sortColumn="category">Issue Type</TableCell>
            <TableCell sortColumn="priority">Priority</TableCell>
            <TableCell sortColumn="isClosed">Status</TableCell>
            <TableCell>Created Date</TableCell>
            <TableCell>Closed Date</TableCell>
            <TableCell sortColumn="ecd" width={100}>
              ECD(Estimated complete date)
            </TableCell>
            <TableCell sortColumn="action" width={100}>
              Actions
            </TableCell>
            <TableCell width={120}>Attachments</TableCell>
            <TableCell sortColumn="remarks">Remarks</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
          {sortedData &&
            sortedData.map((row, index) => (
              <TableRow key={index}>
                {editMode ? (
                  <React.Fragment>
                    <TableCell>
                      <Select
                        label="Equipments"
                        value={auditIssueList[index].equipmentsWithIssue.map(
                          (k, v) => k.equipmentId
                        )}
                        onChange={val => {
                          setChangeEquipments(val, index)
                        }}
                      >
                        {equipment.map((x, index) => (
                          <SelectOption
                            key={index}
                            value={x.equipmentId}
                            label={`${x.equipmentName}`}
                          />
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Textarea
                        value={auditIssueList[index].description}
                        onChange={value => {
                          auditIssueList[index].description = value
                          setAuditIssueList([...auditIssueList])
                        }}
                        placeholder="Enter Description..."
                        width={200}
                      />
                    </TableCell>
                    <TableCell>
                      <AuditSelect
                        value={auditIssueList[index].category}
                        onChange={value => {
                          auditIssueList[index].category = value
                          setAuditIssueList([...auditIssueList])
                        }}
                        options={auditIssueTypeOptions}
                      />
                    </TableCell>
                    <TableCell>
                      <AuditSelect
                        value={auditIssueList[index].priority}
                        onChange={value => {
                          auditIssueList[index].priority = value
                          setAuditIssueList([...auditIssueList])
                        }}
                        options={auditPriorityOptions}
                      />
                    </TableCell>
                    <TableCell>
                      <AuditSelect
                        value={auditIssueList[index].isClosed}
                        onChange={value => {
                          auditIssueList[index].isClosed = value
                          setAuditIssueList([...auditIssueList])
                        }}
                        options={auditIssueStatusOptions}
                      />
                    </TableCell>
                    <TableCell>{auditIssueList[index].createdDate}</TableCell>
                    <TableCell>{auditIssueList[index].resolvedDate}</TableCell>
                    <TableCell>
                      <DatePicker
                        value={auditIssueList[index].ecd}
                        width={180}
                        size="small"
                        onChange={value => {
                          auditIssueList[index].ecd = value
                          setAuditIssueList([...auditIssueList])
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Textarea
                        value={auditIssueList[index].action}
                        onChange={value => {
                          auditIssueList[index].action = value
                          setAuditIssueList([...auditIssueList])
                        }}
                        placeholder="Enter Action..."
                        width={200}
                      />
                    </TableCell>
                    <TableCell>
                      <Text tag="ol">
                        {row.retrieveFileListByEquipment &&
                          row.retrieveFileListByEquipment.map((file, fileIndex) => {
                            return (
                              <li key={file + fileIndex}>
                                <Link
                                  // type="icon"
                                  onClick={() => {
                                    return window.open(
                                      file.presignedUrl,
                                      "_blank"
                                    )
                                  }}
                                  rel="noopener noreferrer"
                                >
                                  {file.fileName}
                                </Link>
                                <Text>
                                  <Link
                                    disabled={!isAdmin}
                                    onClick={() => {
                                      let newFileList = row.retrieveFileListByEquipment.filter((x, i) => i !== fileIndex)
                                    
                                      let newAuditIssueList = auditIssueList.map((x, i) => i === index ? {...x, retrieveFileListByEquipment: newFileList}: x)

                                      setAuditIssueList(newAuditIssueList)
                                    }}
                                  >
                                    <Icon tokens={deleteTokens} />
                                  </Link>
                                </Text>
                              </li>
                            )
                          })}
                      </Text>
                      <Button
                        type="link"
                        onClick={() => {
                          setAddAuditFileModalOpen(true)
                          setCurrentIndex(index)
                        }}
                      >
                        + Add Attachments
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Textarea
                        value={auditIssueList[index].remarks}
                        onChange={value => {
                          auditIssueList[index].remarks = value
                          setAuditIssueList([...auditIssueList])
                        }}
                        placeholder="Enter Remarks..."
                        width={200}
                      />
                    </TableCell>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <TableCell>{row.equipmentNames}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.category}</TableCell>
                    <TableCell>{`P${row.priority}`}</TableCell>
                    <TableCell>
                      <StatusTag status={row.isClosed ? "Closed" : "Open"} />
                    </TableCell>
                    <TableCell>{row.createdDate}</TableCell>
                    <TableCell>{row.resolvedDate}</TableCell>
                    <TableCell>{row.ecd}</TableCell>
                    <TableCell>{row.action}</TableCell>
                    <TableCell>
                      <Text tag="ol">
                        {row.retrieveFileListByEquipment &&
                          row.retrieveFileListByEquipment.map((file, index) => {
                            return (
                              <li key={file + index}>
                                <Link
                                  onClick={() => {
                                    return window.open(
                                      file.presignedUrl,
                                      "_blank"
                                    )
                                  }}
                                  rel="noopener noreferrer"
                                >
                                  {file.fileName}
                                </Link>
                              </li>
                            )
                          })}
                      </Text>
                    </TableCell>
                    <TableCell>{row.remarks}</TableCell>
                    <TableCell>
                      <Text>
                        <Link
                          disabled={!isAdmin}
                          onClick={() =>
                            reduxDispatch(
                              deleteAuditIssues({
                                auditReportId: auditReport.auditReportId,
                                allIssues: {
                                  auditIssueIds: [row.issueId],
                                  cm: cm,
                                  testLocation: testLocation,
                                },
                              })
                            )
                          }
                        >
                          <Icon tokens={deleteTokens} />
                        </Link>
                      </Text>
                    </TableCell>
                  </React.Fragment>
                )}
              </TableRow>
            ))}
        </Table>
        <AddAuditIssuesModal
          addAuditModalOpen={addAuditModalOpen}
          setAddAuditModalOpen={setAddAuditModalOpen}
          cm={cm}
          onAddSuccess={onCloseButtonClick}
          auditReportId={auditReport.auditReportId}
          equipment={equipment}
        />
        <AddAuditFileModal
          addAuditFileModalOpen={addAuditFileModalOpen}
          setAddAuditFileModalOpen={setAddAuditFileModalOpen}
          callback={addAuditIssueFileList}
        />
      </Column>
    </Row>
  )
}

export default AuditReportIssueList
