import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Link from "@amzn/meridian/link"
import Button from "@amzn/meridian/button"
import DateRangePicker from "@amzn/meridian/date-range-picker"
import useVictoryTheme from "@amzn/meridian/use-victory-theme"
import Box from "@amzn/meridian/box"
import Text from "@amzn/meridian/text"
import Divider from "@amzn/meridian/divider"
import { Radar, Pie, Column as AntdColumn, Line } from "@ant-design/plots"

import {
  retrievePerformanceSummaryByCMAvgDays,
  retrievePerformanceSummaryByCMEquipmentCategory,
  retrievePerformanceSummaryByCMEquipmentCM,
  retrievePerformanceSummaryByCMHeader,
  retrievePerformanceSummaryByCMMonth,
  retrievePerformanceSummaryByCMQuarter,
  setCmCmIndex,
  setCmReset,
  setCMStartDate,
} from "../../../redux/performanceSummary/action"
import { Select, SelectOption } from "../../../components/app/form-fields"
import PageLayout from "../../../components/app/page-layout"
import KPICard from "../components/KPICard"
import PerformanceStackedBarChart from "../charts/PerformanceStackedBarChart"
import PerformanceLineChart from "../charts/PerformanceLineChart"
import PerformancePieChart from "../charts/PerformancePieChart"
import PerformanceBarChart from "../charts/PerformanceBarChart"
import {
  sortByMonthData,
  sortByQuarterData,
  transformChartData,
} from "../../../util/Util"

const initialPerformanceCMState = {
  summaryByCmEquipmentCategoryPie: {
    data: [],
  },
  summaryByCmEquipmentCmChart: {
    complianceScoreCMMap: {},
    equipmentCMChartData: {
      data: [],
    },
    scoreTrendChart: {},
    cmScoreData: {
      key: "CM Location",
      label: "CM scores",
      data: [],
    },
  },
  summaryByCmMonthChart: {
    approvedFileChart: [],
    calibrationEquipmentChart: [],
    flaggedEquipmentChart: [],
    unapprovedFileChart: [],
    uploadFileQty: [],
  },
  summaryByCmQuarterChart: {
    labPersonnelQtyChart: [],
    personnelTurnoverRateChart: [],
  },
  summaryByCmAvgDaysChart: {
    avgIssueCloseDays: {
      key: "CM Location",
      label: "Days",
      data: [],
    },
    avgUnflagDays: {
      key: "CM Location",
      label: "Days",
      data: [],
    },
  },
}

const config = {
  xField: "item",
  yField: "score",
  seriesField: "type",
  meta: {
    score: {
      alias: "Score",
      min: 0,
      max: 5,
      // nice: true,
      formatter: v => Number(v).toFixed(2),
    },
  },
  xAxis: {
    line: null,
    tickLine: null,
    label: {
      style: {
        // fill: 'red',
        // opacity: 0.6,
        fontSize: 14,
        fontWeight: 500,
      },
      // rotate: true
    },
    grid: {
      line: {
        style: {
          lineDash: null,
        },
      },
    },
  },
  yAxis: {
    label: {
      style: {
        // fill: 'red',
        // opacity: 0.6,
        // fontSize: 14,
        // fontWeight: 500,
      },
      formatter: v => Number(v).toFixed(0),
    },

    // line: null,
    // tickInterval: 1,
    // tickCount: 5,
  },
  area: {},
  point: {
    size: 2,
  },
}

const equipmentPieConfig = {
  // appendPadding: 10,
  angleField: "value",
  colorField: "label",
  radius: 0.6,
  legend: false,
  label: {
    type: "spider",
    labelHeight: 28,
    content: "{name}\n{value}, {percentage}",
  },
  interactions: [
    {
      type: "element-selected",
    },
    {
      type: "element-active",
    },
  ],
}

const cmScoresColumnConfig = {
  // appendPadding: 10,
  maxColumnWidth: 40,
  xField: "cm",
  yField: "value",
  seriesField: "cm",
  columnWidthRatio: 0.8,
  xAxis: {
    label: {
      autoHide: true,
      autoRotate: true,
    },
  },
  meta: {
    //     type: {
    //       alias: "CM",
    //     },
    //     sales: {
    //       alias: "Scores",

    cm: {
      alias: "CM",
    },
    value: {
      alias: "Scores",
    },
  },
}

const equipCalibrationConfig = {
  maxColumnWidth: 40,
  xField: "date",
  yField: "count",
  seriesField: "cm",
  isStack: true,
  legend: {
    layout: "horizontal",
    position: "top-left",
  },
  // sort: {
  //   reverse: true,
  //   by: "y",
  // },
}
const getRadarData = complianceScoreCMMap => {
  const complianceTypes = {
    calibration: "Calibration",
    capability: "Capability",
    consumableManagement: "Consumable",
    cooperation: "Cooperation",
    maintenance: "Maintenance",
    sop: "E-SOP",
    training: "Training",
  }

  let result = []
  Object.entries(complianceScoreCMMap).forEach(([cmkey, cmValue]) => {
    Object.entries(complianceTypes).forEach(([typeKey, typeValue]) => {
      result.push({
        item: typeValue,
        score: cmValue?.performanceSummaryComplianceDetail?.[typeKey],
        type: cmkey,
      })
    })
  })
  return result
}

const getCalibrationData = d => {
  let sortedData = sortByMonthData(d)
  let result = []
  Object.keys(sortedData).forEach(date => {
    Object.keys(sortedData[date]).forEach(v => {
      result.push({
        date: date,
        cm: v,
        count: sortedData[date][v],
      })
    })
  })
  return result
}

const getQuaterlyData = d => {
  let sortedData = sortByQuarterData(d)
  let result = []
  Object.keys(sortedData).forEach(date => {
    Object.keys(sortedData[date]).forEach(v => {
      result.push({
        date: date,
        cm: v,
        count: sortedData[date][v],
      })
    })
  })
  return result
}

const getScoreTrendData = data => {
  let result = []
  Object.keys(data).forEach(cmValue =>
    data[cmValue].performanceSummaryScoreTrend.map(scoreMap => {
      result.push({
        cm: cmValue,
        score: scoreMap.complianceScore,
        date: scoreMap.date,
      })
    })
  )
  result.sort(function(a, b) {
    return new Date(a.date) - new Date(b.date)
  })
  return result
}

const PerformanceCm = props => {
  const radarTheme = useVictoryTheme({
    showDependentGrid: false,
    showIndependentGrid: false,
    tickLabelSize: "v100",
    tickLabelWeight: "regular",
    axisLabelSize: "v100",
    axisLabelWeight: "regular",
  })

  const [
    summary_by_cm_equipment_category_pie,
    setSummary_by_cm_equipment_category_pie,
  ] = useState(initialPerformanceCMState.summaryByCmEquipmentCategoryPie)
  const [
    summary_by_cm_equipment_cm_chart,
    setSummary_by_cm_equipment_cm_chart,
  ] = useState(initialPerformanceCMState.summaryByCmEquipmentCmChart)

  const [
    summary_by_cm_compliance_chart,
    setSummary_by_cm_compliance_chart,
  ] = useState({})

  const [summary_by_cm_month_chart, setSummary_by_cm_month_chart] = useState(
    initialPerformanceCMState.summaryByCmMonthChart
  )

  const [
    summary_by_cm_quarter_chart,
    setSummary_by_cm_quarter_chart,
  ] = useState(initialPerformanceCMState.summaryByCmQuarterChart)

  const [
    summary_by_cm_avg_days_chart,
    setSummary_by_cm_avg_days_chart,
  ] = useState(initialPerformanceCMState.summaryByCmAvgDaysChart)

  const { locationList } = useSelector(state => state.locationReducer)
  const {
    isLoading,
    summary_by_cm_header,
    summary_by_cm_equipment_category,
    summary_by_cm_equipment_cm,
    summary_by_cm_month,
    summary_by_cm_quarter,
    summary_by_cm_avg_days,
    cmDateRange,
    cmCmIndex,
  } = useSelector(state => state.performanceReducer)
  const reduxDispatch = useDispatch()

  useEffect(() => {
    let startDate = cmDateRange[0]
    let endDate = cmDateRange[1]

    reduxDispatch(
      retrievePerformanceSummaryByCMHeader({
        cm: locationList[cmCmIndex]?.cm,
        cm_location: locationList[cmCmIndex]?.cmLocation,
        start_date: startDate,
        end_date: endDate,
      })
    )

    reduxDispatch(
      retrievePerformanceSummaryByCMEquipmentCategory({
        cm: locationList[cmCmIndex]?.cm,
        cm_location: locationList[cmCmIndex]?.cmLocation,
        start_date: startDate,
        end_date: endDate,
      })
    )

    reduxDispatch(
      retrievePerformanceSummaryByCMEquipmentCM({
        cm: locationList[cmCmIndex]?.cm,
        cm_location: locationList[cmCmIndex]?.cmLocation,
        start_date: startDate,
        end_date: endDate,
      })
    )

    reduxDispatch(
      retrievePerformanceSummaryByCMMonth({
        cm: locationList[cmCmIndex]?.cm,
        cm_location: locationList[cmCmIndex]?.cmLocation,
        start_date: startDate,
        end_date: endDate,
      })
    )
    reduxDispatch(
      retrievePerformanceSummaryByCMQuarter({
        cm: locationList[cmCmIndex]?.cm,
        cm_location: locationList[cmCmIndex]?.cmLocation,
        start_date: startDate,
        end_date: endDate,
      })
    )
    reduxDispatch(
      retrievePerformanceSummaryByCMAvgDays({
        cm: locationList[cmCmIndex]?.cm,
        cm_location: locationList[cmCmIndex]?.cmLocation,
        start_date: startDate,
        end_date: endDate,
      })
    )
  }, [cmCmIndex, cmDateRange, locationList, reduxDispatch])

  const reset = () => {
    reduxDispatch(setCmReset())

    setSummary_by_cm_avg_days_chart(
      initialPerformanceCMState.summaryByCmAvgDaysChart
    )
    setSummary_by_cm_month_chart(
      initialPerformanceCMState.summaryByCmMonthChart
    )
    setSummary_by_cm_equipment_cm_chart(
      initialPerformanceCMState.summaryByCmEquipmentCmChart
    )
    setSummary_by_cm_equipment_category_pie(
      initialPerformanceCMState.summaryByCmEquipmentCategoryPie
    )
    setSummary_by_cm_quarter_chart(
      initialPerformanceCMState.summaryByCmQuarterChart
    )
  }

  useEffect(() => {
    if (summary_by_cm_equipment_category.EquipmentCategoryDataMap != null) {
      let transformedPieEquipmentCategory = {
        data: [],
      }
      Object.entries(
        summary_by_cm_equipment_category.EquipmentCategoryDataMap
      ).map(([key, value]) => {
        transformedPieEquipmentCategory.data.push({
          key: key,
          value: value[0],
          label: key,
        })
      })
      setSummary_by_cm_equipment_category_pie(transformedPieEquipmentCategory)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary_by_cm_equipment_category])

  useEffect(() => {
    let transformedPieEquipmentCM = {
      complianceScoreCMMap: {},
      equipmentCMChartData: {
        data: [],
      },
      scoreTrendChart: {},
      cmScoreData: {
        key: "CM Location",
        label: "CM scores",
        data: [],
      },
    }
    let transformedComplianceCM = {}
    if (summary_by_cm_equipment_cm.EquipmentCMDataMap != null) {
      transformedPieEquipmentCM.data = transformChartData(
        summary_by_cm_equipment_cm.EquipmentCMDataMap
      )
    }

    if (summary_by_cm_equipment_cm.complianceScoreCMMap) {
      transformedPieEquipmentCM.complianceScoreCMMap =
        summary_by_cm_equipment_cm.complianceScoreCMMap
    }

    if (summary_by_cm_equipment_cm.complianceScoreCMMap) {
      transformedPieEquipmentCM.scoreTrendChart =
        summary_by_cm_equipment_cm.complianceScoreCMMap

      transformedPieEquipmentCM.cmScoreData.data = Object.keys(
        summary_by_cm_equipment_cm.complianceScoreCMMap
      ).map(cmValue => {
        let weightedScore =
          summary_by_cm_equipment_cm.complianceScoreCMMap[cmValue][
            "performanceSummaryComplianceDetail"
          ].weightedScore
        return {
          cm: cmValue,
          value: weightedScore,
        }
      })
    }
    // console.log(transformedPieEquipmentCM)
    // console.log(transformedPieEquipmentCM.scoreTrendChart)
    setSummary_by_cm_equipment_cm_chart(transformedPieEquipmentCM)
    // setSummary_by_cm_compliance_chart(transformedComplianceCM)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary_by_cm_equipment_cm])

  useEffect(() => {
    let transformedChartByCMMonth = {
      approvedFileChart: [],
      calibrationEquipmentChart: [],
      flaggedEquipmentChart: [],
      unapprovedFileChart: [],
      uploadFileQty: [],
    }

    if (summary_by_cm_month.CommonPerformanceSummaryMonthResponse != null) {
      transformedChartByCMMonth.uploadFileQty = getCalibrationData(
        summary_by_cm_month.CommonPerformanceSummaryMonthResponse.UploadFileQty
      )
      transformedChartByCMMonth.calibrationEquipmentChart = getCalibrationData(
        summary_by_cm_month.CommonPerformanceSummaryMonthResponse
          .CalibrationEquipmentQty
      )
      transformedChartByCMMonth.unapprovedFileChart = getCalibrationData(
        summary_by_cm_month.CommonPerformanceSummaryMonthResponse
          .UnapprovedFileQty
      )
      transformedChartByCMMonth.approvedFileChart = getCalibrationData(
        summary_by_cm_month.CommonPerformanceSummaryMonthResponse
          .ApprovedFileQty
      )
    }

    setSummary_by_cm_month_chart(transformedChartByCMMonth)
  }, [summary_by_cm_month])

  useEffect(() => {
    let transformedChartByCMQuarter = {
      labPersonnelQtyChart: [],
      personnelTurnoverRateChart: [],
    }

    if (summary_by_cm_quarter.CommonPerformanceSummaryQuarterResponse != null) {
      transformedChartByCMQuarter.labPersonnelQtyChart = getQuaterlyData(
        summary_by_cm_quarter.CommonPerformanceSummaryQuarterResponse
          .LabPersonnelQty
      )
      transformedChartByCMQuarter.personnelTurnoverRateChart = getQuaterlyData(
        summary_by_cm_quarter.CommonPerformanceSummaryQuarterResponse
          .PersonnelTurnoverRate
      )
      setSummary_by_cm_quarter_chart(transformedChartByCMQuarter)
    }
  }, [summary_by_cm_quarter])

  useEffect(() => {
    let transformedChartByCMAvgDays = {
      avgIssueCloseDays: {
        key: "CM Location",
        label: "Days",
        data: [],
      },
      avgUnflagDays: {
        key: "CM Location",
        label: "Days",
        data: [],
      },
    }

    if (
      summary_by_cm_avg_days.CommonPerformanceSummaryAvgDaysResponse != null
    ) {
      transformedChartByCMAvgDays.avgUnflagDays.data = Object.entries(
        summary_by_cm_avg_days.CommonPerformanceSummaryAvgDaysResponse
          .AvgUnflagDays
      ).map((key, value) => {
        return {
          cm: key[0],
          value: key[1],
        }
      })

      transformedChartByCMAvgDays.avgIssueCloseDays.data = Object.entries(
        summary_by_cm_avg_days.CommonPerformanceSummaryAvgDaysResponse
          .AvgIssueCloseDays
      ).map((key, value) => {
        return {
          cm: key[0],
          value: key[1],
        }
      })
    }
    setSummary_by_cm_avg_days_chart(transformedChartByCMAvgDays)
  }, [summary_by_cm_avg_days])

  const d = getRadarData(summary_by_cm_equipment_cm_chart.complianceScoreCMMap)
  console.warn("getradar", d)

  return (
    <PageLayout
      spacing="large"
      alignmentHorizontal={"start"}
      loading={isLoading}
      width="100%"
    >
      <div role="tabpanel" id="tabpanel-planets" aria-labelledby="tab-planets">
        <Row spacingInset="small none">
          <Column>
            <DateRangePicker
              value={cmDateRange}
              onChange={dateRange => reduxDispatch(setCMStartDate(dateRange))}
              startLabel="Start date"
              endLabel="End date"
              monthsInView={2}
            />
          </Column>
          <Column spacing="large">
            <Select
              label="CM Location"
              value={cmCmIndex}
              onChange={x => {
                reduxDispatch(setCmCmIndex(x))
              }}
              minWidth={240}
              size={"small"}
            >
              {locationList.map((x, index) => (
                <SelectOption
                  key={index}
                  value={index}
                  label={`${x.cm}-${x.cmLocation}`}
                />
              ))}
            </Select>
          </Column>
          <Column>
            <Button minWidth={100} type="primary" size="small" onClick={reset}>
              Reset
            </Button>
          </Column>
        </Row>
        {Object.keys(summary_by_cm_header).length > 0 && (
          <div style={{ padding: "20px 0px 20px 0" }}>
            <Row>
              <Column>
                <KPICard
                  kpi={summary_by_cm_header.totalEquipmentQty}
                  title={"Total Equipment QTY"}
                />
              </Column>
              <Column>
                <KPICard
                  kpi={summary_by_cm_header.totalOnboardLabs}
                  title={"Onboard Labs"}
                />
              </Column>
              <Column>
                <KPICard
                  kpi={summary_by_cm_header.totalOnboardCM}
                  title={"Onboard CMs"}
                />
              </Column>
            </Row>
          </div>
        )}
        <Row wrap="down" spacing="500" alignmentVertical="top">
          {summary_by_cm_equipment_category_pie.data.length > 0 && (
            <Box spacingInset="300" type="outline" width={500} height={350}>
              <Text type="h200">{"Equipment QTY by Category"}</Text>
              <Divider />
              <Pie
                // padding={30}
                data={summary_by_cm_equipment_category_pie?.data}
                {...equipmentPieConfig}
              />
            </Box>
          )}
          {summary_by_cm_equipment_cm_chart.equipmentCMChartData.data.length >
            0 && (
            <PerformanceStackedBarChart
              data={summary_by_cm_equipment_cm_chart.equipmentCMChartData.data}
              title={"Equipment QTY by CM"}
            />
          )}
          {summary_by_cm_month_chart.uploadFileQty.length > 0 && (
            <Box spacingInset="300" type="outline" width={500} height={350}>
              <Text type="h200">Upload File QTY by Month</Text>
              <Divider />
              <Line
                padding={[40, 60, 70, 40]}
                data={summary_by_cm_month_chart.uploadFileQty}
                xField={"date"}
                yField={"count"}
                seriesField={"cm"}
                maxColumnWidth={40}
                smooth={true}
              />
            </Box>
            // <PerformanceLineChart
            //   data={summary_by_cm_month_chart.uploadFileQty}
            //   title={"Upload File QTY by Month"}
            //   xValue={"cm"}
            //   yValue={"value"}
            // />
          )}
          {Object.keys(summary_by_cm_equipment_cm_chart.scoreTrendChart)
            .length > 0 && (
            <Box spacingInset="300" type="outline" width={500} height={350}>
              <Text type="h200">Score Trends</Text>
              <Divider />
              <Line
                padding={[40, 60, 70, 40]}
                data={getScoreTrendData(
                  summary_by_cm_equipment_cm_chart.scoreTrendChart
                )}
                xField={"date"}
                yField={"score"}
                seriesField={"cm"}
              />
            </Box>
          )}
          {Object.keys(summary_by_cm_equipment_cm_chart.complianceScoreCMMap)
            .length > 0 && (
            <Box spacingInset="300" type="outline" width={500} height={350}>
              <Text type="h200">CM Strength</Text>
              <Divider />
              <Radar
                padding={70}
                data={getRadarData(
                  summary_by_cm_equipment_cm_chart.complianceScoreCMMap
                )}
                xField="item"
                yField="score"
                seriesField="user"
                {...config}
              />
            </Box>
          )}

          {summary_by_cm_equipment_cm_chart.cmScoreData.data.length > 0 && (
            <Box spacingInset="300" type="outline" width={500} height={350}>
              <Text type="h200">{"CM Scores"}</Text>
              <Divider />
              <AntdColumn
                padding={[40, 60, 70, 40]}
                data={summary_by_cm_equipment_cm_chart.cmScoreData.data}
                {...cmScoresColumnConfig}
              />
            </Box>
          )}
          {summary_by_cm_month_chart.calibrationEquipmentChart.length > 0 && (
            <Box spacingInset="300" type="outline" width={500} height={350}>
              <Text type="h200">Calibration Equipment by Month</Text>
              <Divider />
              <AntdColumn
                padding={[40, 60, 70, 40]}
                data={summary_by_cm_month_chart.calibrationEquipmentChart}
                {...equipCalibrationConfig}
              />
            </Box>
          )}
          {/* {summary_by_cm_month_chart.calibrationEquipmentChart.length > 0 && (
            <PerformanceStackedBarChart
              data={summary_by_cm_month_chart.calibrationEquipmentChart}
              title={"Calibration Equipment by Month"}
            />
          )} */}
          {summary_by_cm_month_chart.unapprovedFileChart.length > 0 && (
            <Box spacingInset="300" type="outline" width={500} height={350}>
              <Text type="h200">Unapproved File QTY by Month</Text>
              <Divider />
              <AntdColumn
                padding={[40, 60, 70, 40]}
                data={summary_by_cm_month_chart.unapprovedFileChart}
                {...equipCalibrationConfig}
              />
            </Box>
            // <PerformanceStackedBarChart
            //   data={summary_by_cm_month_chart.unapprovedFileChart}
            //   title={"Unapproved File QTY by Month"}
            // />
          )}
          {summary_by_cm_month_chart.approvedFileChart.length > 0 && (
            <Box spacingInset="300" type="outline" width={500} height={350}>
              <Text type="h200">Approved File QTY by Month</Text>
              <Divider />
              <AntdColumn
                padding={[70, 60, 70, 40]}
                data={summary_by_cm_month_chart.approvedFileChart}
                {...equipCalibrationConfig}
              />
            </Box>
            // <PerformanceStackedBarChart
            //   data={summary_by_cm_month_chart.approvedFileChart}
            //   title={"Approved File QTY by Month"}
            // />
          )}
          {summary_by_cm_quarter_chart.labPersonnelQtyChart.length > 0 && (
            <Box spacingInset="300" type="outline" width={500} height={350}>
              <Text type="h200">Personnel QTY by Quarter in Past Year</Text>
              <Divider />
              <Line
                padding={[40, 60, 70, 40]}
                data={summary_by_cm_quarter_chart.labPersonnelQtyChart}
                xField={"date"}
                yField={"count"}
                seriesField={"cm"}
              />
            </Box>
          )}
          {summary_by_cm_quarter_chart.personnelTurnoverRateChart.length >
            0 && (
            <Box spacingInset="300" type="outline" width={500} height={350}>
              <Text type="h200">Personnel Turn Over Rate by Quarter</Text>
              <Divider />
              <Line
                padding={[40, 60, 70, 40]}
                data={summary_by_cm_quarter_chart.personnelTurnoverRateChart}
                xField={"date"}
                yField={"count"}
                seriesField={"cm"}
                smooth={true}
              />
            </Box>
          )}
          {summary_by_cm_month_chart.flaggedEquipmentChart.length > 0 && (
            <PerformanceLineChart
              data={summary_by_cm_month_chart.flaggedEquipmentChart}
              title={"Flagged Equipment by Month"}
              xValue={"cm"}
              yValue={"value"}
            />
          )}
          {summary_by_cm_avg_days_chart.avgUnflagDays.data.length > 0 && (
            <PerformanceBarChart
              data={summary_by_cm_avg_days_chart.avgUnflagDays}
              title={"Flagged Equipment by Month"}
              xValue={"cm"}
              yValue={"value"}
            />
          )}
          {summary_by_cm_avg_days_chart.avgIssueCloseDays.data.length > 0 && (
            <Box spacingInset="300" type="outline" width={500} height={350}>
              <Text type="h200">Average Issue Close Day</Text>
              <Divider />
              <AntdColumn
                padding={[70, 60, 70, 40]}
                data={summary_by_cm_avg_days_chart.avgIssueCloseDays.data}
                xField="cm"
                yField="value"
                seriesField="cm"
                maxColumnWidth="40"
              />
            </Box>
          )}
        </Row>
      </div>
      <Row>
        <Text>{"Reference: "}</Text>
        <Link href="https://wiki.labcollab.net/confluence/display/RELIABILITY/CM+SBR+Score+Summary" target="_blank" type="b200">
          CM SBR Score Summary
        </Link>
      </Row>
      
    </PageLayout>
  )
}

export default PerformanceCm
